<template>
  <section>
    <div class="logo">
      <router-link to="/">
        <img
          src="@/assets/images/logo-lowco.png"
          alt="Lowco logo"
        >
      </router-link>
    </div>
    <form @submit.prevent="onSubmit">
      <div class="control">
        <input
          name="username"
          type="text"
          @change="onChange"
        >
        <label for="username">Email</label>
      </div>
      <div class="control">
        <input
          name="password"
          type="password"
          @change="onChange"
        >
        <label for="password">Mot de passe</label>
      </div>
      <button
        class="lowco-button"
        type="submit"
      >
        Se connecter
      </button>
      <router-link
        to="/password-lost"
        class="lost_password"
      >
        Mot de passe oublié ?
      </router-link>

      <div
        v-if="isSubmitted && error"
        class="lowco-textbox lowco-textbox-error"
        v-html="getErrorLabel()"
      />
      <div
        v-else-if="isSubmitted"
        class="lowco-textbox lowco-textbox-success"
      >
        Connexion réussie
      </div>

    </form>
  </section>
</template>

<script>
import lowcoApi from '@/api/lowco-api';
import loginConstants from '@/constants/login.constants';

export default {
  name: 'Login',
  data() {
    return {
      formData: null,
      isSubmitted: false,
      error: null,
    };
  },
  methods: {
    onChange(event) {
      const { name, value } = event.target;
      this.formData = {
        ...this.formData,
        [name]: value,
      };
    },
    async onSubmit() {
      const hasEmptyValue = Object.keys(this.formData).some((key) => !this.formData[key]);

      if (hasEmptyValue) {
        this.error = loginConstants.EMPTY_FIELD;
        return;
      }

      try {
        await lowcoApi.authenticate(this.formData);
        this.$router.push('/home');
        this.error = null;
      } catch (err) {
        this.error = loginConstants.LOGIN_ERROR;
      } finally {
        this.isSubmitted = true;
      }
    },
    getErrorLabel() {
      switch (this.error) {
        case loginConstants.EMPTY_FIELD:
          return 'Veuillez remplir tous les champs';
        case loginConstants.LOGIN_ERROR:
          return 'Une erreur est survenue à la connexion';
        default:
          return 'Une erreur générale est survenue, veuillez réssayer plus tard';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/common/variables.scss";
@import "../assets/styles/common/mixins.scss";

@include centeredLayoutWithLogo();
@include centeredLayoutWithLogoForm();

.lost_password {
  display: block;
  margin: 1.5rem auto 0;
  font-size: 1.3rem;
  text-align: center;
  width: fit-content;
}
</style>
